import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la/'
import { videos } from 'src/constants/videos'
import { laChannelCount } from 'src/constants/laPlanData'
import { useCheckFanduelRSN } from 'src/modules/hooks/useCheckFanduelRSN'

export default (props) => {
  const [showFDPlanCards, rsnData] = useCheckFanduelRSN('mlb')

  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="es"
      market="latino"
      video={videos.mlbtv.videoUrl}
      title="Mira a tu equipo local de la MLB"
      subtitle={`Accede a FanDuel Sports Network y más de ${laChannelCount} canales de TV en vivo y deportes en español`}
      networkSet="entertainmentLatino"
      customNetworks={['espndeportes']}
      noteLink="/la/latino-plus-beisbol/"
      fanduelRSNData={rsnData}
      pageDescription={
        'Transmite canales de FanDuel Sports Network, FOX Deportes, ESPN Deportes, beIN SPORTS y más con Fubo.'
      }
    />
  )
}
