import {
  WEB_MARKETING_VALUE_PROPS_HERO,
  WEB_MARKETING_ADCASH_CTA_URL,
  WEB_MARKETING_MLB_TV_W_TRIAL,
  WEB_MARKETING_FD_TEAM_MSG,
} from './constants'

import type { BooleanValueFlag, Flag, StringValueFlag } from './types'

type MarketingValuePropsHero = typeof WEB_MARKETING_VALUE_PROPS_HERO
type MarketingAdcashCtaUrl = typeof WEB_MARKETING_ADCASH_CTA_URL
type MarketingMLBTVWTrial = typeof WEB_MARKETING_MLB_TV_W_TRIAL
type MarketingFdTeamMsg = typeof WEB_MARKETING_FD_TEAM_MSG
function flag<K extends string, V>(key: K, value: V, ttl = -1): Flag<K, V> {
  return {
    key,
    value,
    ttl,
  }
}
function booleanFlag<K extends string>(key: K, value = false, ttl = -1): BooleanValueFlag<K> {
  return flag<K, boolean>(key, value, ttl)
}
function stringFlag<K extends string>(key: K, value = 'default', ttl = -1): StringValueFlag<K> {
  return flag<K, string>(key, value, ttl)
}

export const initValues = {
  [WEB_MARKETING_VALUE_PROPS_HERO]: stringFlag<MarketingValuePropsHero>(
    WEB_MARKETING_VALUE_PROPS_HERO
  ),
  [WEB_MARKETING_ADCASH_CTA_URL]: stringFlag<MarketingAdcashCtaUrl>(WEB_MARKETING_ADCASH_CTA_URL),
  [WEB_MARKETING_MLB_TV_W_TRIAL]: stringFlag<MarketingMLBTVWTrial>(WEB_MARKETING_MLB_TV_W_TRIAL),
  [WEB_MARKETING_FD_TEAM_MSG]: stringFlag<MarketingFdTeamMsg>(WEB_MARKETING_FD_TEAM_MSG),
} as const

export type StacFlags = typeof initValues
export type StacFlagKey = keyof StacFlags
export type StacFlagKeys = StacFlagKey[]

export const supportedFlags = Object.keys(initValues) as StacFlagKeys
