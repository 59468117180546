import React, { useEffect } from 'react'
import CompareHeroSection from 'src/components/template/compare/components/sections/CompareHeroSection'
import Layout from 'src/components/common/Layout'
import SEO from 'src/components/common/SEO'
import styled from 'styled-components'
import GeneralCompareSection from 'src/components/template/compare/components/sections/GeneralCompareSection'
import ComparePlansSection from 'src/components/template/compare/components/sections/ComparePlansSection'
import CompareChannelsSection from 'src/components/template/compare/components/sections/CompareChannelsSection'
import CompleteChannelComparison from 'src/components/template/compare/components/sections/CompleteChannelComparison'
import CompareFaqs from 'src/components/template/compare/components/sections/CompareFaqs'
import CompareProsConsSection from 'src/components/template/compare/components/sections/CompareProsConsSection'
import CompareHeader from 'src/components/template/compare/components/sections/CompareHeader'
import CompareFooter from 'src/components/template/compare/components/sections/CompareFooter'
import CompareInterlinkingSection from 'src/components/template/compare/components/sections/CompareInterlinkingSection'
import { attachCssVariables } from 'src/modules/design-system/styles/utils'

const DefaultComparePage = ({ lang = 'en', market = 'us', data }) => {
  const {
    competitor,
    competitorColor,
    heroData,
    generalCompareData,
    prosConsData,
    plansData,
    channelsData,
    completedChannelsData,
    faqData,
  } = data

  const meta = {
    title: `fubo vs. ${competitor}: Which is better? | 2023 Comparison`,
    description: `Looking for the best streaming service? Compare fubo vs ${competitor} per price, features, channel lineup or add-ons. Review which is better for you.`,
  }

  useEffect(() => {
    attachCssVariables()
  })

  return (
    <Layout lang={lang} market={market} noFooter>
      <SEO lang={lang} market={market} title={meta.title} description={meta.description} />
      <StyledDefaultComparePage>
        <CompareHeader />
        <CompareHeroSection competitor={competitor} data={heroData} />
        <GeneralCompareSection competitor={competitor} data={generalCompareData} />
        <CompareProsConsSection competitor={competitor} data={prosConsData} />
        <ComparePlansSection
          competitor={competitor}
          competitorColor={competitorColor}
          data={plansData}
        />
        <CompareChannelsSection competitor={competitor} data={channelsData} />
        <CompleteChannelComparison competitor={competitor} data={completedChannelsData} />
        <CompareInterlinkingSection competitor={competitor} />
        <CompareFaqs data={faqData} />
        <CompareFooter />
      </StyledDefaultComparePage>
    </Layout>
  )
}

export default DefaultComparePage

const StyledDefaultComparePage = styled.div`
  --logo-height: 25px;
  background: #000f26;
  p {
    margin-top: 0;
  }
`
