import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la/'
import { videos } from 'src/constants/videos'
import { laChannelCount } from 'src/constants/laPlanData'
import { useCheckFanduelRSN } from 'src/modules/hooks/useCheckFanduelRSN'

export default (props) => {
  const [showFDPlanCards, rsnData] = useCheckFanduelRSN('mlb')

  let title = (
    <>
      Mira la MLB en vivo <span className="no-wrap">en español</span>
    </>
  )
  let subtitle = `Disfruta del béisbol toda la temporada y accede a más de 50 canales de TV en vivo en español`
  if (rsnData?.teams) {
    title = (
      <>
        Mira la MLB <span className="no-wrap">en vivo</span>
      </>
    )
    subtitle = `Sigue a tu equipo local con FanDuel Sports Network y más de ${laChannelCount} canales de TV en vivo`
  }

  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="es"
      market="latino"
      video={videos.mlbtv.videoUrl}
      title={title}
      subtitle={subtitle}
      networkSet="entertainmentLatino"
      customNetworks={['espndeportes']}
      noteLink="/la/mlb/"
      fanduelRSNData={rsnData}
      pageDescription={
        'Transmite canales de FanDuel Sports Network, FOX Deportes, ESPN Deportes, beIN SPORTS y más con Fubo.'
      }
    />
  )
}
