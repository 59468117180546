import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la/'
import { videos } from 'src/constants/videos'
import { laChannelCount } from 'src/constants/laPlanData'
import { useCheckFanduelRSN } from 'src/modules/hooks/useCheckFanduelRSN'

export default (props) => {
  const [showFDPlanCards, rsnData] = useCheckFanduelRSN('mlb')

  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="en"
      market="latino"
      video={videos.mlbtv.videoUrl}
      title="Watch your local MLB team"
      subtitle={`Get FanDuel Sports Network and ${laChannelCount}+ channels of live TV & sports in Spanish`}
      networkSet="entertainmentLatino"
      customNetworks={['espndeportes']}
      noteLink="/la/latino-plus-beisbol-es/"
      fanduelRSNData={rsnData}
      pageDescription={
        'Stream FanDuel Sports Network, FOX Deportes, ESPN Deportes, beIN SPORTS and more with Fubo.'
      }
    />
  )
}
