import React from 'react'
import Image from 'src/components/common/Image'
import styled from 'styled-components'
import { ratePlanCodes } from './ratePlanCode'
import { networkLogos } from './networkLogos'

export const laPriceMonthly = 14.99
export const laDiscountPriceMonthly = 9.99
export const laChannelCount = 50

// Latino Baseball pricing
export const laOneRsnFirstMonth = 24.99
export const laOneRsnRegularPrice = 32.99
export const laTwoRsnFirstMonth = 29.99
export const laTwoRsnRegularPrice = 39.99

const CashAppBulletStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  // flex-wrap: wrap;
  white-space: nowrap;
  img {
    width: 160px;
    margin-top: 0;
    margin-left: 10px;
    object-fit: contain;
    @media (max-width: 992px) {
      width: 150px;
    }
  }
`
const CashAppLogo = () => (
  <Image
    src={'https://assets-imgx.fubo.tv/marketing/lp/images/apps/cashapppay-logomark.png?w=200'}
  />
)

const CashAppWhiteLogo = () => (
  <Image
    src={'https://assets-imgx.fubo.tv/marketing/lp/images/networks/cashapp-logo-m-v2.png?w=170'}
  />
)

const formatTeamsText = (teams = [], lang = 'en') => {
  if (!teams?.length) return ''

  if (teams.length === 1) {
    return teams[0]
  } else if (teams.length === 2) {
    const connector = lang === 'en' ? 'and' : 'y'
    return `${teams[0]} ${connector} ${teams[1]}`
  } else {
    const allButLast = teams.slice(0, -1).join(', ')
    const last = teams[teams.length - 1]
    const connector = lang === 'en' ? ', and' : ', y'
    return `${allButLast}${connector} ${last}`
  }
}

const formatRsnText = (rsns = [], lang = 'en') => {
  if (!rsns?.length) return ''

  if (rsns.length === 1) {
    return rsns[0]
  } else if (rsns.length === 2) {
    const connector = lang === 'en' ? 'and' : 'y'
    return `${rsns[0]} ${connector} ${rsns[1]}`
  } else {
    const allButLast = rsns.slice(0, -1).join(', ')
    const last = rsns[rsns.length - 1]
    const connector = lang === 'en' ? ', and' : ', y'
    return `${allButLast}${connector} ${last}`
  }
}

const networkList = [
  {
    logo: networkLogos.espndeportes.logoWhite,
    alt: networkLogos.espndeportes.alt,
    width: 55,
    height: 24,
  },
  {
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/fox-deportes-vertical-m.png',
    alt: networkLogos.foxdeportes.alt,
    width: 55,
    height: 24,
  },
  {
    logo: networkLogos.bein.logoWhite,
    alt: networkLogos.bein.alt,
    width: 75,
    height: 19,
  },
]

export const laPlanData = (channels, fanduelRSNData = null, isDarkMode = false) => {
  const isFanduel = fanduelRSNData != null
  let rsnRatePlanCode = ratePlanCodes.latino
  let hasMultipleRsns = false
  let firstMonthPrice = false
  let regularPrice = false

  if (isFanduel) {
    // Determine rate plan code and pricing based on RSN count
    hasMultipleRsns = fanduelRSNData?.rsn?.length > 1
    rsnRatePlanCode = hasMultipleRsns
      ? ratePlanCodes.latino_baseball_2rsn
      : ratePlanCodes.latino_baseball_1rsn
    firstMonthPrice = hasMultipleRsns ? laTwoRsnFirstMonth : laOneRsnFirstMonth
    regularPrice = hasMultipleRsns ? laTwoRsnRegularPrice : laOneRsnRegularPrice
  }

  // Get RSN names for display
  const rsnNames =
    isFanduel && fanduelRSNData?.rsn
      ? fanduelRSNData.rsn.map((network) =>
          typeof network === 'string' ? network : network.name || ''
        )
      : []

  return {
    en: {
      plan: 'latino',
      offerCode: ratePlanCodes.latino,
      title: 'Latino Plan',
      subtitle: 'TV in Spanish, live soccer and other sports, shows, news, and more.',
      tagline: 'The best Spanish language TV, live soccer, news, entertainment and more.',
      price: laPriceMonthly,
      discountedPrice: laDiscountPriceMonthly,
      listItems: [
        ...(isFanduel
          ? [
              {
                available: false,
                text: formatRsnText(rsnNames, 'en'),
              },
              {
                available: false,
                text: formatTeamsText(fanduelRSNData?.teams, 'en'),
              },
            ]
          : []),
        { available: true, text: `${laChannelCount}+ channels of live TV and sports` },
        { available: true, text: 'Unlimited Cloud DVR' },
        ...(isFanduel
          ? []
          : [
              { available: true, text: '2 Screens at once with Standard Share.' },
              //REMOVED: univision_removed
              // { available: true, text: 'Liga MX, UEFA Champions League, La Liga, and more sports.' },
              //REPLACED: univision_removed
              {
                available: true,
                text: 'La Liga, Ligue 1, Serie A, Liga BetPlay, and more sports.',
              },
            ]),
        {
          available: true,
          text: (
            <CashAppBulletStyled>
              Now accepting
              {isDarkMode ? <CashAppWhiteLogo /> : <CashAppLogo />}
            </CashAppBulletStyled>
          ),
        },
      ],
      networkLogos: [...networkList, { alt: '+ MORE' }],
      footnote: {
        // monthly: '',
        monthly: `*Offer valid for the first month. Renews at $${laPriceMonthly}/mo. Additional taxes and fees may apply. Prices subject to change. Cancel anytime.`,
        // quarterly:
        //   `*Prepaid for 3 months at $${laPriceMonthly}/mo. Renews at $${laPriceMonthly}/3mo. Additional taxes and fees may apply. Prices subject to change. Cancel anytime.`,
      },
    },
    es: {
      plan: 'latino',
      offerCode: ratePlanCodes.latino,
      title: 'Plan Latino',
      subtitle: 'TV en español, deportes en vivo, telenovelas, noticias y mucho más.',
      tagline: 'TV en español, fútbol en vivo, telenovelas, noticias y mucho más.',
      price: laPriceMonthly,
      discountedPrice: laDiscountPriceMonthly,
      networkLogos: [...networkList, { alt: 'Y MÁS!' }],
      listItems: [
        ...(isFanduel
          ? [
              {
                available: false,
                text: formatRsnText(rsnNames, 'es'),
              },
              {
                available: false,
                text: formatTeamsText(fanduelRSNData?.teams, 'es'),
              },
            ]
          : []),
        { available: true, text: `Más de ${laChannelCount} canales de TV y deportes en vivo.` },
        { available: true, text: 'Espacio ilimitado en tu Cloud DVR.' },
        ...(isFanduel
          ? []
          : [
              { available: true, text: '2 Pantallas a la vez con Standard Share' },
              //REMOVED: univision_removed
              //{ available: true, text: 'Liga MX, UEFA Champions League, La Liga, y mucho más deportes.' },
              //REPLACED: univision_removed
              { available: true, text: 'La Liga, Ligue 1, Serie A, Liga BetPlay y mucho más' },
            ]),
        {
          available: true,
          text: (
            <CashAppBulletStyled>
              Ahora aceptamos
              {isDarkMode ? <CashAppWhiteLogo /> : <CashAppLogo />}
            </CashAppBulletStyled>
          ),
        },
      ],
      footnote: {
        // monthly: '',
        monthly: `*Oferta válida solo para el primer mes. Se renueva a $${laPriceMonthly}/mes. Pueden aplicarse impuestos y otros cargos. Precios sujetos a cambios. Cancela cuando quieras.`,
        // quarterly:
        //   '*Prepago por 3 meses a $74.97. Se renueva a $99/trimestre. Pueden aplicarse impuestos y otros cargos. Precios sujetos a cambios. Cancela cuando quieras.',
      },
    },
    en_plus_beisbol: {
      plan: 'latinoBaseball',
      offerCode: rsnRatePlanCode,
      title: 'Latino Plan + Béisbol',
      subtitle: 'Local MLB team coverage plus soccer, NBA & more TV in Spanish',
      tagline: 'The best Spanish language TV, live soccer, news, entertainment and more.',
      price: regularPrice,
      discountedPrice: firstMonthPrice,
      banner: 'Local MLB team coverage plus more!',
      listItems: [
        ...[
          {
            available: true,
            text: formatRsnText(rsnNames, 'en'),
          },
          {
            available: true,
            text: formatTeamsText(fanduelRSNData?.teams, 'en'),
          },
        ],

        { available: true, text: `${laChannelCount}+ channels of live TV and sports` },
        { available: true, text: 'Unlimited Cloud DVR' },

        {
          available: true,
          text: (
            <CashAppBulletStyled>
              Now accepting
              {isDarkMode ? <CashAppWhiteLogo /> : <CashAppLogo />}
            </CashAppBulletStyled>
          ),
        },
      ],
      footnote: {
        monthly: `*Offer valid for the first month. Renews at $${regularPrice}/mo. Additional taxes and fees may apply. Prices subject to change. Cancel anytime. FanDuel Sports Network broadcasts in English only.`,
      },
      promoImg:
        'https://assets-imgx.fubo.tv/marketing/lp/images/card/us/fanduel-poster-plan-card.png',

      networkLogos: [
        {
          logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/fanduel-horizontal-c-v2.png',
          alt: networkLogos.fanduelsports.alt,
          width: 44,
          height: 44,
        },
        ...networkList,
        { alt: '+ MORE' },
      ],
    },
    es_plus_beisbol: {
      plan: 'latinoBaseball',
      offerCode: rsnRatePlanCode,
      title: 'Plan Latino + Béisbol',
      subtitle: 'Cobertura del equipo local de la MLB, además de fútbol, NBA y más TV en español.',
      tagline: 'TV en español, fútbol en vivo, telenovelas, noticias y mucho más.',
      price: regularPrice,
      discountedPrice: firstMonthPrice,
      banner: 'Cobertura de tu equipo local de la MLB',
      promoImg:
        'https://assets-imgx.fubo.tv/marketing/lp/images/card/us/fanduel-poster-plan-card.png',
      listItems: [
        {
          available: true,
          text: formatRsnText(rsnNames, 'es'),
        },
        {
          available: true,
          text: formatTeamsText(fanduelRSNData?.teams, 'es'),
        },
        { available: true, text: `Más de ${laChannelCount} canales de TV y deportes en vivo` },
        { available: true, text: 'Espacio ilimitado en tu Cloud DVR' },
        {
          available: true,
          text: (
            <CashAppBulletStyled>
              Ahora aceptamos
              {isDarkMode ? <CashAppWhiteLogo /> : <CashAppLogo />}
            </CashAppBulletStyled>
          ),
        },
      ],
      footnote: {
        monthly: `*Oferta válida solo para el primer mes. Se renueva a $${regularPrice}/mes. Pueden aplicarse impuestos y otros cargos. Precios sujetos a cambios. Cancela cuando quieras. FanDuel Sports Network ofrece contenido solo en inglés.`,
      },
      networkLogos: [
        {
          logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/fanduel-horizontal-c-v2.png',
          alt: networkLogos.fanduelsports.alt,
          width: 44,
          height: 44,
        },
        ...networkList,
        { alt: 'Y MÁS!' },
      ],
    },
  }
}
