import React from 'react'
import GolfTemplate from 'src/components/page/golf'
import { promoCodes } from 'src/constants'
import { leagueGroupIds } from 'src/constants/personalizationIds'
import styled from 'styled-components'

// Five Iron Golf Member Offer expires February 14, 2026
export default (props) => (
  <StyledContainer>
    <GolfTemplate
      location={props.location}
      poster={'https://assets-imgx.fubo.tv/marketing/lp/images/posters/golf_Web_v3.png'}
      posterMobile={'https://assets-imgx.fubo.tv/marketing/lp/images/posters/golf_Mobile_v3.jpg'}
      title="Fubo has the most golf"
      subtitle={'Stream 120+ live events from the PGA Tour & more'}
      offerBanner={
        <>
          ✔ Special Five Iron Offer
          <br />
          Enjoy $30 off each month for 2 months.
        </>
      }
      offerCode={promoCodes.five_iron_golf_members}
      matchtickerLeagueId={leagueGroupIds.golf}
      logo={
        'https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-five-iron-golf.png'
      }
    />
  </StyledContainer>
)

const StyledContainer = styled.div``
