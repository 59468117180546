import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { getEnvironment } from 'src/config/env'
import { selectMarketState, selectPostalCode } from 'src/modules/reducers/market'
import { getUniqueLeagues } from 'src/modules/utils/league'

export const packages = {
  'DETROIT ONLY': { offerCode: 'us-recu-ga-fanduel_det-mo-7d-v1', price: 19.99 },
  'FLORIDA + SUN': { offerCode: 'us-recu-ga-fanduel_florida_sun-mo-7d-v1', price: 29.99 },
  'MIDWEST + NORTH': { offerCode: 'us-recu-ga-fanduel_mw_n-mo-7d-v1', price: 29.99 },
  'MIDWEST + OHIO': {
    offerCode: 'us-recu-ga-fanduel_mw_oh-mo-7d-v1',
    redsOfferCode: 'us-recu-mk-fanduel_mw_oh_reds-mo-7d-v1',
    price: 29.99,
  },
  'MIDWEST ONLY': { offerCode: 'us-recu-ga-fanduel_mw-mo-7d-v1', price: 19.99 },
  'NORTH ONLY': { offerCode: 'us-recu-ga-fanduel_n-mo-7d-v1', price: 19.99 },
  'OHIO + DETROIT': {
    offerCode: 'us-recu-ga-fanduel_oh_det-mo-7d-v1',
    redsOfferCode: 'us-recu-mk-fanduel_oh_det_reds-mo-7d-v1',
    price: 29.99,
  },
  'OHIO + SOUTH + SOUTHEAST': {
    offerCode: 'us-recu-ga-fanduel_oh_s_se-mo-7d-v1',
    redsOfferCode: 'us-recu-mk-fanduel_oh_s_se_reds-mo-7d-v1',
    price: 29.99,
  },
  'OHIO + SOUTH': {
    offerCode: 'us-recu-ga-fanduel_oh_s-mo-7d-v1',
    redsOfferCode: 'us-recu-mk-fanduel_oh_s_reds-mo-7d-v1',
    price: 29.99,
  },
  'OHIO ONLY': {
    offerCode: 'us-recu-ga-fanduel_oh-mo-7d-v1',
    redsOfferCode: 'us-recu-mk-fanduel_oh_reds-mo-7d-v1',
    price: 19.99,
  },
  'OKLAHOMA ONLY': { offerCode: 'us-recu-ga-fanduel_ok-mo-7d-v1', price: 19.99 },
  'SOCAL + WEST': { offerCode: 'us-recu-ga-fanduel_sc_ws-mo-7d-v1', price: 29.99 },
  'SOUTH + SOUTHEAST': {
    offerCode: 'us-recu-ga-fanduel_s_se-mo-7d-v1',
    redsOfferCode: 'us-recu-mk-fanduel_s_se_reds-mo-7d-v1',
    price: 29.99,
  },
  'SOUTH ONLY': { offerCode: 'us-recu-ga-fanduel_s-mo-7d-v1', price: 19.99 },
  'SOUTHWEST ONLY': { offerCode: 'us-recu-ga-fanduel_sw-mo-7d-v1', price: 19.99 },
  'WEST ONLY': { offerCode: 'us-recu-ga-fanduel_w-mo-7d-v1', price: 19.99 },
  'WISCONSIN ONLY': { offerCode: 'us-recu-ga-fanduel_wi-mo-7d-v1', price: 19.99 },
  'NORTH + WISCONSIN': { offerCode: 'us-recu-ga-fanduel_n_wi-mo-7d-v1', price: 29.99 },
  'SOUTH + SOUTHEAST + SOUTHWEST': {
    offerCode: 'us-recu-ga-fanduel_s_se_sw-mo-7d-v1',
    redsOfferCode: 'us-recu-mk-fanduel_s_se_sw_reds-mo-7d-v1',
    price: 29.99,
  },
}

const env = getEnvironment()

export const useCheckFanduelRSN = (sportType = null) => {
  // const postalCode = '30007'
  // const regionCode = 'GA'
  const postalCode = useSelector(selectPostalCode)
  const { regionCode } = useSelector(selectMarketState)
  const [showFDPlanCards, setShowFDPlanCards] = useState(false)
  const [rsnData, setRSNData] = useState(null)

  // Fetch RSN data for Fanduel Sports
  const fetchRSNs = (postalCode, regionCode) => {
    return fetch(`${env.default.host}/data/fd_data/fd_${regionCode}.json`)
      .then((response) => response.json())
      .then((data) => {
        const postalCodeData = data[postalCode]
        if (postalCodeData) {
          //Validate by sport type if provided
          if (sportType) {
            const isIncluded = postalCodeData?.teams
              ? getUniqueLeagues(postalCodeData.teams).includes(sportType)
              : false
            if (!isIncluded) {
              return null
            }
          }

          //Check if the package is available for the postal code
          const pkg = postalCodeData?.package
          if (packages[pkg]?.offerCode) {
            return { ...postalCodeData, ...packages[pkg], regionCode }
          }
          return null
        }
      })
      .catch((error) => console.error('Error fetching data:', error))
  }

  // Check if Fanduel Sports is available in the user's postal code
  useEffect(() => {
    if (postalCode) {
      let isMounted = true
      fetchRSNs(postalCode, regionCode)
        .then((result) => {
          if (isMounted && result) {
            setShowFDPlanCards(true)
            setRSNData(result)
          }
        })
        .catch((error) => console.error('Error fetching data:', error))

      return () => {
        isMounted = false // Cleanup function to set isMounted to false
      }
    }
  }, [postalCode, regionCode])

  return [showFDPlanCards, rsnData]
}
