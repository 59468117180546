import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la/'
import { laChannelCount, laDiscountPriceMonthly } from 'src/constants/laPlanData'
import { useCheckFanduelRSN } from 'src/modules/hooks/useCheckFanduelRSN'

export default (props) => {
  const [showFDPlanCards, rsnData] = useCheckFanduelRSN('mlb')
  const isFanduel = rsnData != null

  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="es"
      market="latino"
      //REMOVED: univision_removed video and annotations
      // video={videos.latino.videoUrl}
      // annotations={videos.latino.annotations}
      //REPLACED: univision_removed poster images
      poster="https://assets-imgx.fubo.tv/marketing/lp/images/posters/LaLiga_Ver1_Web.jpg"
      posterMobile="https://assets-imgx.fubo.tv/marketing/lp/images/posters/LaLiga_Ver1_Mobile.jpg"
      title={
        <>
          Ahorra en TV <span className="no-wrap">en vivo para</span>{' '}
          <span className="no-wrap">toda la familia</span>
        </>
      }
      subtitle={
        <>
          Más de {laChannelCount} canales con deportes, noticias y{' '}
          <span className="desktop-line-break">
            entretenimiento en español desde solo ${laDiscountPriceMonthly} el primer mes.
          </span>
        </>
      }
      networkSet="entertainmentLatino"
      noteLink="/latino/"
      showPlusBeisbol={isFanduel}
      disablePlanCards={isFanduel}
      fanduelRSNData={rsnData}
      forceAnchorLink={isFanduel}
    />
  )
}
